
<template>
  <div class="container">
    <Box
      v-loading="loading"
      :title="this.$t('bc33a09')"
      style="margin-bottom: 0"
    >
      <el-table
        :data="tableData"
        style="width: 100%"
        class="tableContainer"
        header-cell-class-name="headerCellClass"
        tooltip-effect="dark"
      >
        <el-table-column
          width="80"
          :label="$t('285e24a')"
          :formatter="(_, __, ___, i) => (query.page - 1) * query.size + i + 1"
        />
        <el-table-column :label="this.$t('7e42717')" show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="content" @click="downChange(scope.row)">{{
              scope.row.name
            }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="generatedTime" :label="this.$t('bc33a10')" />
      </el-table>
      <div style="padding-top: 30px; padding-bottom: 40px">
        <Pagination
          class="pagination"
          :page-info="query"
          @changePage="changePage"
        />
      </div>
    </Box>
  </div>
</template>

<script>
import Api from "@/api/userCenter"
import Pagination from "@/components/Pagination"
import Box from "@/components/Box"
import { downloadFile, haveReportDown, dealJump, dealReportModalClose } from "../../../utils/reportDown"
import API from '@/api'

export default {
  name: "Download",
  components: {
    Pagination,
    Box,
  },
  data() {
    return {
      loading: false,
      query: {
        page: 1,
        size: 10,
        total: 0,
      },
      tableData: [],
    }
  },
  beforeCreate() {
    dealReportModalClose()
  },
  created() {
    this.getData()
  },
  beforeDestroy() {
    this.getReportResult()
  },
  methods: {
    handleSearchData() {
      const data = {
        page: this.query.page,
        size: this.query.size,
      }
      return data
    },
    getData() {
      this.loading = true
      const data = this.handleSearchData()
      // this.getUnread();
      Api.getMyDownload(data)
        .then((res) => {
          this.handleRes(res)
        })
        .catch((e) => {})
        .then(() => {
          this.loading = false
        })
    },
    handleRes(res) {
      console.log("res", res)
      this.query.total = res.page.total
      this.tableData = res.content
    },
    changePage(val) {
      this.query.page = val
      this.getData()
    },
    downChange(row) {
      console.log(row)
      haveReportDown(row.id, () => {
        const el = document.createElement("a")

        el.href = row.url
        el.target = "_blank"
        el.style.display = "none"
        // el.download = `${row.name}.pdf`;
        document.body.appendChild(el)
        el.click()
        document.body.removeChild(el) // 下载完成移除元素
      })
    },

    // ------
    showNotification(data) {
      const _this = this
      _this.$notify({
        customClass: 'reportNotify',
        message: <div>{data.name}报告已提交请点击此处<a onclick={() => {
          downloadFile(data, (data) => {
            haveReportDown(data.id, this.getReportResult)
          })
        }}>下载企业报告。</a><div>企业报告记录请到<a onclick={() => { this.oldReportId = null; dealJump('/usercenter/download') }}>个人中心-我的下载</a>查看。</div></div>,
        duration: 0,
        onClick: () => {
          this.oldReportId = null
          const storyTimeoutId = sessionStorage.getItem('timeoutId')
          storyTimeoutId && clearTimeout(storyTimeoutId)
          sessionStorage.setItem('timeoutId', '')
        }
      })
    },
    getReportResult() {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId)
      }
      this.timeoutId = setInterval(() => {
        API.common.getReport().then(res => {
          if (res && res?.url) {
            // clearTimeout(this.timeoutId)
            // sessionStorage.setItem('timeoutId', '')
            if (res?.id !== this.oldReportId) {
              this.oldReportId = res?.id
              this.showNotification(res)
            }
          }
        })
      }, 5000)
      sessionStorage.setItem('timeoutId', this.timeoutId)
    },
  },
}
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";
.container {
  width: 920px;
  .tableContainer {
    /deep/ .headerCellClass {
      font-size: 13px;
      font-weight: bold;
      color: #424242;
    }
    /deep/ .active {
      color: #f5222d;
    }
    /deep/ .dot {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      display: inline-block;
      width: 4px;
      height: 4px;
      margin-right: 5px;
      background: @uni-text-color-select;
    }
    /deep/ .content:hover {
      color: @uni-text-color-select;
      cursor: pointer;
    }
  }
}
</style>
